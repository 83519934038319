<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ $t("_data.edit_tag") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click.stop="closePopover"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="popover-body tags-body">
        <div class="row">
          <el-checkbox-group v-model="originTags">
            <el-checkbox
              v-for="tag in tagsList"
              :label="tag.id"
              :key="tag.id"
              class="py-1"
              >{{ tag.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="row mt-1 py-3">
          <label for="tag_name" class="col-form-label text-right">{{
            $t("_data.tag_name")
          }}</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" v-model="tag_name" />
          </div>
          <el-button @click="addTag" type="text" size="small">{{
            $t("_data.add_tag")
          }}</el-button>
        </div>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("close") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitTags"
        >
          {{ $t("confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tagsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedTags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    subject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tag_name: "",
      originTags: this.selectedTags,
    };
  },
  watch: {
    selectedTags: function (val, oldVal) {
      this.originTags = val;
    },
  },
  methods: {
    addTag() {
      this.$emit("addTag", this.tag_name);
      this.tag_name = "";
    },
    submitTags() {
      this.$emit("submitTags", this.originTags);
      this.originTags = [];
    },
    closePopover() {
      this.$emit("closeTagsPopover");
    },
  },
};
</script>

<style lang="less">
.brain-popover .tags-body {
  padding-left: 80px;
}
.data-manage .tags-body .el-checkbox {
  margin-bottom: 10px;
}
.tags-body {
  .el-checkbox-group {
    width: 100%;
  }
  .el-checkbox {
    min-width: 20%;
  }
  .el-checkbox__label {
    color: #212528;
  }
}
</style>
