<template>
  <div class="add-subject">
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a class="text-dark" @click="$router.back(-1)">
            <strong>
              <i class="fa fa-chevron-left mr5"></i>
              {{ $t("back") }}
            </strong>
          </a>
        </li>
        <li class="breadcrumb-item text-grey" aria-current="page">
          {{ $t("nav.data_manage") }}
        </li>
        <li class="breadcrumb-item text-dark" aria-current="page">
          {{ $t("_data.add_data") }}
        </li>
      </ol>
    </nav>
    <div class="p-5 m-3 bg-white border rounded">
      <h5>
        {{ $t("_data.add_data") }}
      </h5>
      <form>
        <!-- <div class="row" v-if="!subjectId">
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t("_data.p_name") }}</label>
              <input
                type="text"
                v-model="subject_name"
                name="subject_name"
                class="form-control"
                placeholder
              />
            </div>
          </div>
        </div> -->
        <!-- <div class="row" v-if="!subjectId">
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t("_data.diagnostic") }}</label>
              <textarea
                v-model="description"
                name="description"
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>{{ $t("_data.img_files") }}</label>
              <el-upload
                class="upload-file"
                ref="upload"
                accept=".zip"
                drag
                action
                :http-request="uploadImgFile"
                :on-remove="handleRemoveImage"
                :limit="1"
                :on-exceed="handLimit"
                :before-upload="beforeUpload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("_data.drag_upload") }}
                  <em>{{ $t("_data.click_upload") }}</em>
                </div>
                <!-- <div slot="tip" class="el-upload__tip">
                  <span class="mr10">{{ $t("_data.only_zip") }}</span>
                </div> -->
              </el-upload>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label>{{ $t("_data.other_file") }}</label>
              <el-upload
                class="upload-file"
                ref="upload"
                drag
                action
                :http-request="uploadOtherFile"
                :on-remove="handleRemoveOther"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("_data.drag_upload") }}
                  <em>{{ $t("_data.click_upload") }}</em>
                </div>
              </el-upload>
            </div>
          </div>
        </div> -->

        <div class="row mt-3">
          <div class="col-12">
            <small class="mr-3"
              ><el-button
                type="success"
                plain
                icon="el-icon-collection-tag"
                size="mini"
                @click.stop="openTagModel"
                >添加标签</el-button
              >
            </small>
            <span
              v-for="(t, i) in tagnameList"
              :key="i"
              class="tags-span mr-1"
              >{{ t }}</span
            >
          </div>
        </div>

        <div class="row mt-3 pt-3">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary"
                @click.stop="handleSubmit"
              >
                {{ $t("save") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <el-dialog
      :title="'上传进度' + uploadProgress"
      :visible.sync="showUploadProgress"
      width="300px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
    </el-dialog>

    <set-tags
      :tagsList="tagsList"
      :selectedTags="tagids"
      :showPopover="showTagsPopover"
      @closeTagsPopover="showTagsPopover = false"
      @addTag="addTag"
      @submitTags="submitTags"
    ></set-tags>
  </div>
</template>

<script>
import { getUploadParams, submitSubject, tagsRequest } from "../../api/api";

import { uniqid, getsuffix } from "../../utils/utils";
import { Loading } from "element-ui";

import setTags from "../../components/subject/setTags";
export default {
  components: {
    setTags,
  },
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      // subject_name: "",
      // description: "",
      filebytes: "",
      multiple: true,
      subjectId: this.$route.query.id,
      uploadProgress: "",
      showUploadProgress: false,
      imageFolder: [],
      imageFilenames: [],
      imageFileSize: 0,
      otherFolder: [],
      otherFilenames: [],
      otherFileSize: 0,
      isloading: false,
      showTagsPopover: false,
      tagsList: [], //api get
      tagids: [],
      tagname: "",
      tagnameList: [], //列出选中的标签名
      //   uploadAction: "",
    };
  },
  methods: {
    handLimit(files, fileList) {
      this.$helper.warningMessage(this.$t("_data.only_onefile"));

      //this.$message.warning(
      //   `当前限制选择 1 个文件，本次选择了 ${
      //     files.length
      //   } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      // );

      return;
    },
    handleRemoveImage(file) {
      // const _index = this.imageFilenames.indexOf(file.name)
      const _index = this.findFileIndex(this.imageFilenames, file);

      if (_index > -1) {
        this.imageFilenames.splice(_index, 1);
        this.imageFolder.splice(_index, 1);
        this.imageFileSize -= file.size;
      }
    },
    handleRemoveOther(file) {
      // const _index = this.otherFilenames.indexOf(file.name)
      const _index = this.findFileIndex(this.otherFilenames, file);
      if (_index > -1) {
        this.otherFilenames.splice(_index, 1);
        this.otherFolder.splice(_index, 1);
        this.otherFileSize -= file.size;
      }
    },
    approx(bytevalue) {
      //计算文件单位和大小
      let units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let filebtout = "";
      for (
        let n = 0, Approx = bytevalue / 1024;
        Approx > 1;
        Approx /= 1024, n++
      ) {
        filebtout = Approx.toFixed(3) + " " + units[n];
      }
      return filebtout;
    },
    findFileIndex(fileList, file) {
      function findFileItem(fileItem) {
        return fileItem.name === file.name;
      }
      const _index = fileList.findIndex(findFileItem);
      return _index;
    },
    //上传影像文件成功将文件信息保存
    uploadImgFile(file) {
      let _self = this;

      const filename =
        uniqid() + "_" + _self.$store.state.userid + getsuffix(file.file.name);

      console.log("filename::", filename);
      let uploadParams = getUploadParams({
        userid: this.$store.state.userid,
      });
      uploadParams.then((res) => {
        let getUploadJson = this.uploadXhr(
          res,
          "ProcessNew/dicom/",
          file.file,
          filename
        );

        let loadFile = {
          uniqid_name: filename,
          name: file.file.name,
        };

        const _index = this.findFileIndex(this.imageFilenames, loadFile);

        getUploadJson.then(
          function (status) {
            if (status === 200 && _index === -1) {
              _self.imageFolder.push(res.dir + "ProcessNew/dicom/");
              _self.imageFilenames.push(loadFile);
              // _self.imageFilenames.push(filename)
              _self.imageFileSize += file.file.size;
            }
          },
          function (err) {
            console.log(err);
          }
        );
      });
    },
    //上传其它文件成功将文件信息保存
    uploadOtherFile(file) {
      let _self = this;
      const filename =
        uniqid() + "_" + _self.$store.state.userid + getsuffix(file.file.name);
      let uploadParams = getUploadParams({
        userid: this.$store.state.userid,
      });

      uploadParams.then((res) => {
        let getUploadJson = this.uploadXhr(
          res,
          "ProcessNew/others/",
          file.file,
          filename
        );

        let loadFile = {
          uniqid_name: filename,
          name: file.file.name,
        };

        const _index = this.findFileIndex(this.imageFilenames, loadFile);

        getUploadJson.then(
          function (status) {
            if (status === 200 && _index === -1) {
              _self.otherFolder.push(res.dir + "ProcessNew/others/");
              //_self.otherFilenames.push(file.file.name)
              _self.otherFilenames.push(loadFile);
              _self.otherFileSize += file.file.size;
            }
          },
          function (err) {
            console.log(err);
          }
        );
      });
    },
    //文件上传到服务器
    uploadXhr: function (uploadRes, folder, file, filename) {
      let self = this;
      let promise = new Promise(function (resolve, reject) {
        let handler = function () {
          if (this.status === 200) {
            self.showUploadProgress = false;
            resolve(this.status);
          } else {
            self.showUploadProgress = false;
            reject(new Error(this.statusText));
          }
        };
        file.thumb = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("success_action_status", "200");
        formData.append("OSSAccessKeyId", uploadRes.accessid);
        formData.append("policy", uploadRes.policy);
        formData.append("Signature", uploadRes.signature);
        formData.append("key", uploadRes.dir + folder + filename);
        formData.append("file", file, filename);

        let client = new XMLHttpRequest();
        client.open("POST", uploadRes.host, true);
        client.upload.onprogress = function (e) {
          self.showUploadProgress = true;
          if (e.lengthComputable) {
            const percentComplete = (e.loaded / e.total) * 100;
            self.uploadProgress = parseInt(percentComplete) + "%";
            if (percentComplete === 100) {
              // self.showUploadProgress = false
            }
          }
        };
        client.onreadystatechange = handler;
        client.send(formData);
      });
      return promise;
    },
    async handleSubmit() {
      let projectid = this.$route.query.projectid;
      if (
        this.imageFilenames.length === 0 &&
        this.otherFilenames.length === 0
      ) {
        this.$helper.warningMessage(this.$t("notice.select_data_upload"));
        return;
      }
      let _self = this;
      if (_self.isloading) return;

      _self.isloading = true;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        request: "insert",
        id: _self.subjectId || "0",
        userid: this.user.id || "",
        projectid: projectid || "0",
        groupid: this.$route.query.groupid || "0",
        bucket: "pdunion",
        fileinfo: {
          image: {
            folder: this.imageFolder,
            filenames: this.imageFilenames,
            filesize: this.imageFileSize,
          },
          other: {
            folder: this.otherFolder,
            filenames: this.otherFilenames,
            filesize: this.otherFileSize,
          },
        },
        tagids: _self.tagids,
      };
      try {
        let res = await submitSubject(params);
        if (res.status === 200) {
          _self.isloading = false;
          _self.$message(res.message);
          if (projectid > 0) {
            _self.$router.push("/project/data?id=" + projectid);
          } else {
            _self.$router.push("/data-manage/list");
          }

          // this.$helper.successNotify(
          //   res.message,
          //   function () {
          //     _self.isloading = false;
          //     _self.$router.push("/data-manage/list");
          //   },
          //   100
          // );
        } else {
          _self.isloading = false;
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        _self.isloading = false;
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    beforeUpload(file) {
      const isLt2G = file.size / 1024 / 1024 < 2048;
      if (!isLt2G) {
        this.$message.error("上传文件大小不能超过 2G!");
      }
      return isLt2G;
    },

    //获取标签列表
    openTagModel() {
      let _self = this;

      this.getTagList(function () {
        _self.showTagsPopover = true;
      });
    },
    async getTagList(callBack) {
      let params = {
        request: "list",
        userid: this.user.id, //this.$store.state.userid,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.tagsList = res.data.length > 0 ? res.data : [];
        }
        callBack && callBack();
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },

    //给包裹设置标签
    async submitTags(tagids) {
      this.tagnameList = [];
      this.tagids = tagids;
      this.tagsList.forEach((t) => {
        if (tagids.indexOf(t.id) >= 0) this.tagnameList.push(t.name);
      });
      this.showTagsPopover = false;

      console.log(this.tagids, this.tagsList, this.tagnameList);
    },

    //添加新的标签
    async addTag(tagName) {
      //let _self = this;

      if (!tagName) {
        this.$helper.warningMessage(this.$t("tags.tag_message_require"));
        return false;
      }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "insert",
        userid: this.user.id, //this.$store.state.userid,
        name: tagName,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getTagList();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
  },
};
</script>

<style lang="less">
.add-subject {
  .rounded {
    padding-top: 1rem !important;
  }
  .upload-file .el-button {
    width: 350px;
  }
  .el-dialog__header {
    padding: 20px;
  }
  .el-dialog__body {
    display: none;
  }
}
.tags-span {
  display: inline-block;
  padding: 4 10px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  background: #1db436;
  color: #fff;
  margin-top: 1px;
}
</style>